import React from 'react';

import partner1 from '../assets/images/partner-logo-wef.png'; // Tell Webpack this JS file uses this image
import partner2 from '../assets/images/CGA.png'; // Tell Webpack this JS file uses this image
import partner3 from '../assets/images/Fortinet.png'; // Tell Webpack this JS file uses this image

const Partners = () => {
  return (
    //  the return() to put your default HTML into the DOM
    <div className="wef-section Bgc(gray)">
      <div className="slds-text-align_center">In partnership with the World Economic Forum.</div>
      <div className="slds-container--large slds-container--center">
        <div className="slds-grid slds-wrap slds-grid_align-center slds-grid_pull-padded-large">
          <div className="slds-card tds-card wef-card-partner slds-m-around--medium slds-align--absolute-center">
            <a
              className="slds-text-align_center"
              href="https://www.globalcyberalliance.org/who-we-are/"
              target="_blank">
              <img src={partner2}/>
            </a>
          </div>
          <div className="slds-card tds-card wef-card-partner slds-m-around--medium slds-align--absolute-center">
            <a
              className="slds-text-align_center"
              href="https://training.fortinet.com/local/staticpage/view.php?page=nse"
              target="_blank">
              <img src={partner3}/>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
